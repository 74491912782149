import { Router } from '@reach/router';
import SearchPage from '../../components/pages/search/SearchPage';

const Search = () => (
  <Router>
    <SearchPage path="/search/:genre" />
    <SearchPage path="/" default />
  </Router>
);

export default Search;
