import { PaginationItem, Link, Pagination as MuiPagination } from '@mui/material';
import { connectPagination } from 'react-instantsearch-core';
import { useLocation } from '@reach/router';
import { useMemo } from 'react';

type PaginationProps = {
  currentRefinement: number;
  nbPages: number;
  refine: (value: number) => void;
  createURL: (value: number) => string;
};

const Pagination = connectPagination(({ nbPages, refine, createURL }: PaginationProps) => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = useMemo(() => parseInt(query.get('page') || '1', 10), [query]);

  if (!nbPages) {
    return null;
  }

  return (
    <MuiPagination
      count={nbPages}
      page={page}
      onChange={(_, value) => refine(value)}
      variant="outlined"
      shape="rounded"
      renderItem={(item) => (
        <PaginationItem
          component={Link}
          href={createURL(Number(item?.page))}
          {...item}
        />
      )}
    />
  );
});

export default Pagination;
