import {
  Box, Button, Grid, Skeleton, Stack, Typography,
} from '@mui/material';
import { Plus } from 'mdi-material-ui';
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core';
import Void from '../../icons/Void';

const StateResults = ({ searchResults, error, searching }: StateResultsProvided) => (
  <Box component="section" textAlign="center">
    {!searching && !searchResults?.nbPages && !error && (
    <>
      <Void sx={{ marginBottom: 2 }} />
      <Typography
        variant="h5"
        component="h1"
        gutterBottom
        fontWeight="600"
      >
        No results found.
      </Typography>
      <Typography gutterBottom>
        Try refining your query or you can suggest us a new book.
      </Typography>
      <Button
        variant="contained"
        component="a"
        href={`mailto:support@litireso.africa?subject=${encodeURI('I would like to suggest a new book')}`}
        target="_blank"
        startIcon={<Plus />}
      >
        Suggest book
      </Button>
    </>
    )}

    {searching && (
      <Stack rowGap={3}>
        {Array.from({ length: 20 }).fill(null).map((_, index) => (
          <Grid container key={`${index + 1}`}>
            <Grid item xs={2}>
              <Skeleton variant="rectangular" width={80} height={100} />
            </Grid>
            <Grid item xs="auto">
              <Skeleton variant="rectangular" width={400} sx={{ marginBottom: 2 }} />
              <Skeleton variant="rectangular" width={300} sx={{ marginBottom: 2 }} />
              <Skeleton variant="rectangular" width={200} />
            </Grid>
          </Grid>
        ))}
      </Stack>
    )}

    {error && (
      <Typography>
        We encountered an error while performing your search.
        Please check your network connection and try again.
      </Typography>
    )}
  </Box>
);

export default connectStateResults(StateResults);
