import { Rating } from '@mui/material';
import { RefinementListExposed } from 'react-instantsearch-core';
import CheckboxRefinement from './CheckboxRefinement';

const RatingRefinement = (props: RefinementListExposed & { label: string }) => (
  <CheckboxRefinement
    renderRefinementItem={(value) => <Rating value={Number(value)} readOnly />}
    {...props}
  />
);

export default RatingRefinement;
