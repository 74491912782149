import {
  Configure,
  Stats,
} from 'react-instantsearch-dom';
import {
  Box, DialogContent, DialogTitle, Grid, IconButton, Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { FilterVariant } from 'mdi-material-ui';
import { RouteComponentProps, useMatch } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../../app/Container';
import Button from '../../app/Button';
import Dialog from '../../app/Dialog';
import Spacer from '../../app/Spacer';
import useAuth from '../../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import SearchProvider from '../../../providers/SearchProvider';
import SEO from '../../app/SEO';
import useUrlSync from '../../../providers/SearchProvider/useUrlSync';
import { indexName } from '../../../helpers/config';
import CheckboxRefinement from './CheckboxRefinement';
import ClearRefinements from './ClearRefinements';
import RatingRefinement from './RatingRefinement';
import SearchBox from './SearchBox';
import CurrentRefinements from './CurrentRefinements';
import Hits from './Hits';
import Pagination from './Pagination';
import StateResults from './StateResults';
import HitsViewed from './HitsViewed';

const gqlQuery = graphql`
  query FacetGenresSearch {
    allGenre {
      nodes {
        title
        slug
      }
    }
  }
`;

const Filters = () => {
  const pageMatch = useMatch('/search/:genre');
  const { allGenre } = useStaticQuery<Queries.FacetGenresSearchQuery>(gqlQuery);
  const genre = useMemo(() => (
    allGenre.nodes.find(({ slug }) => slug === pageMatch?.genre)
  ), [pageMatch, allGenre]);

  const handleTransformItems = useCallback((items: Record<string, string>[]) => (
    items.filter((item) => (!pageMatch ? true : item.label === genre?.title))
  ), [pageMatch, genre]);

  return (
    <Stack rowGap={3}>
      <ClearRefinements />
      <CheckboxRefinement attribute="type" label="Publication Type" />
      <CheckboxRefinement attribute="language" label="Language" limit={5} />
      <CheckboxRefinement
        transformItems={handleTransformItems}
        attribute="genres"
        label="Genre"
        limit={5}
      />
      <CheckboxRefinement attribute="pricingType" label="Pricing" />
      <CheckboxRefinement attribute="publisherName" label="Publisher" limit={5} />
      <RatingRefinement attribute="rating" label="Rating" />
    </Stack>
  );
};

const SearchPage: FC<RouteComponentProps<{genre:string}>> = ({ genre }) => {
  const { getCategoryName } = useUrlSync();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const auth = useAuth();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const genreTitle = genre ? getCategoryName(genre) : undefined;

  return (
    <SearchProvider>
      <SEO title={genre ? `${genreTitle} books` : 'Search'} />
      <Container data-insights-index={indexName}>
        <Box paddingY={5}>
          <Configure
            hitsPerPage={20}
            analytics
            distinct
            attributesToSnippet={['title', 'description']}
            snippetEllipsisText="…"
            clickAnalytics
            userToken={auth?.id}
          />
          <Box width={800} maxWidth="100%" margin="auto" marginBottom={10}>
            <Typography textAlign="center" gutterBottom variant="h3" fontWeight="600">
              {genre ? `${genreTitle} books` : 'Search'}
            </Typography>
            <SearchBox sx={{ width: '100%' }} autoFocus />
          </Box>
          <Grid container columnGap={8}>
            <Grid item xs={12} md={3}>
              {!isMobile && <Box display={{ xs: 'none', md: 'unset' }}><Filters /></Box>}

              {isMobile && (
              <Box marginBottom={3}>
                <Button
                  variant="text"
                  startIcon={<FilterVariant />}
                  onClick={() => setIsFilterOpen(true)}
                >
                  Filters
                </Button>
                <Dialog
                  disablePortal
                  keepMounted
                  open={isFilterOpen}
                  onClose={() => setIsFilterOpen(false)}
                  fullWidth
                  maxWidth="sm"
                >
                  <Stack flexDirection="row" alignItems="center">
                    <DialogTitle sx={{ fontWeight: '600' }}>Filters</DialogTitle>
                    <Spacer />
                    <IconButton onClick={() => setIsFilterOpen(false)}><Close /></IconButton>
                  </Stack>
                  <DialogContent>
                    <Filters />
                  </DialogContent>
                </Dialog>
              </Box>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <HitsViewed />
              <Box marginBottom={2}><Stats /></Box>
              <CurrentRefinements />
              <StateResults />
              <Hits />
              <Box marginTop={3} justifyContent="center" display="flex">
                <Pagination />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SearchProvider>
  );
};

export default SearchPage;
