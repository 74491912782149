/* eslint-disable no-underscore-dangle */
import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { BasicDoc, Hit } from 'react-instantsearch-core';
import { connectHits } from 'react-instantsearch-dom';
import useAuth from '../../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import { trackSelectItem, toGtagItem } from '../../../helpers/gtag';
import { PricingSchemaType } from '../../../types/app';
import { BookSchema } from '../../../types/schema';
import ProductCardHit from '../../app/ProductCardHit';

const Hits = connectHits<BookSchema & BasicDoc>(({ hits }) => {
  const auth = useAuth();

  const handleClick = useCallback((hit: Hit<BookSchema>) => () => {
    trackSelectItem('Search List', toGtagItem([
      {
        title: hit.title,
        slug: hit.slug,
        pricings: hit.pricings as unknown as PricingSchemaType[],
        pricingType: hit.pricingType,
      },
    ], { currencyCode: auth?.currencyCode, listName: 'Search List', quantity: 1 })[0]);
  }, [auth?.currencyCode]);

  return (
    <Grid
      container
      columnSpacing={{ xs: 2, md: 0 }}
      rowSpacing={{ xs: 2, md: 0 }}
    >
      {hits.map((hit) => (
        <Grid
          data-insights-object-id={hit.objectID}
          data-insights-position={hit.__position}
          data-insights-query-id={hit.__queryID}
          key={hit.objectID}
          item
          xs
          md="auto"
          display="flex"
        >
          <ProductCardHit hit={hit} onClick={handleClick(hit)} />
        </Grid>
      ))}
    </Grid>
  );
});

export default Hits;
