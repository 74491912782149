import { useEffect } from 'react';
import { connectHits } from 'react-instantsearch-core';

const HitsViewed = connectHits(() => {
  useEffect(() => {
    window.dataLayer.push({ event: 'Hits Viewed' });
  }, []);

  return null;
});

export default HitsViewed;
