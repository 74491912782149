import { Typography, Stack, Chip } from '@mui/material';
import { memo, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { CurrentRefinementsProvided, Refinement } from 'react-instantsearch-core';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

type CurrentRefinementsProps = CurrentRefinementsProvided;

const label = {
  language: 'Language',
  genres: 'Genres',
  pricingType: 'Pricing',
  type: 'Publication Type',
  rating: 'Rating',
  publisherName: 'Publisher',
};

const CurrentRefinements = ({
  items,
  refine,
}: CurrentRefinementsProps) => {
  const handleClick = useCallback((refinement?: Partial<Refinement>) => () => {
    if (!refinement?.value) {
      return;
    }

    refine(refinement?.value);
  }, [refine]);

  const renderRefinements = useCallback((refinements: Partial<Refinement>[]): JSX.Element => (
    <>
      {refinements.map((refinement) => {
        if (Array.isArray(refinement.items)) {
          return renderRefinements(refinement.items.map((item) => ({
            ...item,
            attribute: refinement.attribute,
          })));
        }

        return (
          <Chip
            key={`${refinement?.label}${refinement?.attribute}`}
            onClick={handleClick(refinement)}
            onDelete={handleClick(refinement)}
            label={(
              <Stack flexDirection="row" columnGap={1}>
                <Typography
                  fontWeight="600"
                >
                  {refinement.attribute ? `${label[refinement.attribute as keyof typeof label]}:` : null}
                </Typography>
                <Typography>{refinement.label}</Typography>
              </Stack>
            )}
          />
        );
      })}
    </>
  ), [handleClick]);

  if (!items.length) return null;

  return (
    <Stack marginBottom={3} flexDirection="row" flexWrap="wrap" columnGap={2}>
      <Typography
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        Selected filters:
      </Typography>
      <Stack flexDirection="row" flexWrap="wrap" columnGap={3} rowGap={1}>
        {renderRefinements(items)}
      </Stack>
    </Stack>
  );
};

export default connectCurrentRefinements(memo(CurrentRefinements, isEqual));
