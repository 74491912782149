import { connectCurrentRefinements } from 'react-instantsearch-dom';
import Button from '../../app/Button';

const ClearRefinements = connectCurrentRefinements(({ refine, items }) => (
  <Button onClick={() => refine(items)} disabled={!items.length} variant="outlined">
    Clear all filters
  </Button>
));

export default ClearRefinements;
