import { useCallback, VFC } from 'react';
import { DialogProps as BaseDialogProps, Dialog as BaseDialog } from '@mui/material';

type DialogProps = BaseDialogProps & {
  persistent?: boolean;
}

const Dialog: VFC<DialogProps> = ({ persistent, onClose, ...props }) => {
  const handleClose = useCallback<Required<DialogProps>['onClose']>((event, reason) => {
    if (persistent && reason === 'backdropClick') return undefined;

    return onClose?.(event, reason);
  }, [onClose, persistent]);

  return (
    <BaseDialog {...props} onClose={handleClose} />
  );
};

export default Dialog;
