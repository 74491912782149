import { Typography } from '@mui/material';
import { VFC } from 'react';

export type RefinementLabelProps = {
  label: string;
}

const RefinementLabel: VFC<RefinementLabelProps> = ({ label }) => (
  <Typography fontWeight={600} gutterBottom variant="h6">{label}</Typography>
);

export default RefinementLabel;
