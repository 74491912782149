import {
  CardContent, FormGroup, FormControlLabel, Stack, Typography,
  Card, Chip, Checkbox,
} from '@mui/material';
import { connectRefinementList, RefinementListProvided } from 'react-instantsearch-core';
import RefinementLabel from './RefinementLabel';

export type CheckboxRefinementProps = RefinementListProvided & {
  label: string;
  attribute?: string;
  renderRefinementItem?: (value: string) => JSX.Element;
};

const CheckboxRefinement = connectRefinementList(({
  refine, items, currentRefinement, label, canRefine,
  renderRefinementItem, attribute,
}: CheckboxRefinementProps) => {
  if (!items.length) return null;

  return (
    <Card>
      <CardContent>
        <RefinementLabel label={label} />
        <FormGroup>
          {items.map((item) => (
            <FormControlLabel
              data-insights-filter={`${attribute}:${item.value}`}
              key={item.label}
              disabled={!canRefine}
              className="refinement-item"
              control={(
                <Checkbox
                  checked={currentRefinement.includes(item.label)}
                  onChange={() => refine(item.value) as undefined}
                />
              )}
              label={(
                <Stack flexDirection="row" columnGap={2}>
                  {renderRefinementItem?.(item.label) || <Typography sx={{ textTransform: 'capitalize' }}>{item.label}</Typography>}
                  <Chip size="small" variant="filled" label={item.count} />
                </Stack>
              )}
            />
          ))}
        </FormGroup>
      </CardContent>
    </Card>
  );
});

export default CheckboxRefinement;
